import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as GoogleAnalytics from './lib/google-analytics';
import { rootReducer, rootSaga } from './stores';
import ScrollToTop from './components/ScrollToTop';
import PagePC from './components/views/PagePC';
import Page404 from './components/views/Page404';
import BookingList from './containers/BookingListContainer';
import TermsOfUseList from './containers/TermsOfUseListContainer';
import KakaoAuth from './containers/KakaoAuthContainer';
import KaKaoAuthSuccess from './containers/KaKaoAuthSuccessContainer';
import RoomList from './containers/RoomListContainer';
import CheckOutSuccess from './containers/CheckOutSuccessContainer';

const { Kakao } = window as any;
if (!Kakao.isInitialized()) Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);

GoogleAnalytics.initialize({measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID});

const App = () => {
  const persistConfig = { 
    key: 'root',
    whitelist: ['booking'],
    storage
  };
  
  const enhancedReducer = persistReducer(persistConfig, rootReducer);
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    enhancedReducer, 
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  
  sagaMiddleware.run(rootSaga);
  
  const persistor = persistStore(store);

  const isMobile = () => {
    const pc = 'win16|win32|win64|mac|macintel';
    if (navigator.platform) {
      if (pc.indexOf(navigator.platform.toLowerCase()) < 0) return true;
      else return false;
    }
    else return true;
  };

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              {isMobile() ?
                <>
                  <Route path='/' element={<BookingList />} />
                  <Route path='/terms_of_use' element={<TermsOfUseList />} />
                  <Route path='/auth' element={<KakaoAuth />} />
                  <Route path='/auth/success' element={<KaKaoAuthSuccess />} />
                  <Route path='/room' element={<RoomList />} />
                  <Route path='/checkout/success' element={<CheckOutSuccess />} />
                  <Route path='*' element={<Page404 />} />
                </>
              : (
                <Route path='*' element={<PagePC />}/>
              )}
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
